<template>
	<div class="page-content about-page lead">
		<div class="about-page__header">
			<img :src="logoPath" />
			<h3>Vic’s Science Studio End User License Agreement</h3>
		</div>
		<p>
			By clicking the “accept” button, downloading, installing or otherwise using the software (as defined below),
			you agree to be bound by the Terms and Conditions of this End User License Agreement (“EULA”). Please read
			these Terms and Conditions carefully. This is a legally binding contract between you and Sole Entrepreneur
			<b class="about-page__header__name">Andrei Dubrovskii</b> , address Agátová 3460/7F, 84101 Bratislava -
			Dubravka, the Slovak Republic (“licensor”), a company duly organized and existing under the laws of Slovak
			Republic (trading as Vic’s Science Studio).
		</p>
		<h5>Definitions</h5>
		<table class="about-page__table">
			<tbody class="about-page__table__tbody">
				<tr>
					<th>User</th>
					<th>
						a person who has registered and (or) authorised in accordance with section 4 of this EULA, and
						has a Personal Account. For the purposes of this EULA, the User also means a person who has not
						registered, but accesses the Website and/or uses and/or has used it. By accessing the Website,
						Users confirm that they agree with the EULA and requirements established herein.
					</th>
				</tr>
				<tr>
					<th>Documentation</th>
					<th>the documentation for the Software that made available by the Licensor to the User</th>
				</tr>
				<tr>
					<th>Software</th>
					<th>
						Vic’s Science Studio virtual reality platform that means all computer programs licensed under
						this EULA, including all related technical information, files, Documentation, and other
						materials, whether in whole or in part, including any and all modifications, derivative works
						and copies of the foregoing, regardless of the form or media in or on which they may exist
					</th>
				</tr>
				<tr>
					<th>Website</th>
					<th><router-link :to="{ name: 'LandingEn' }">https://vics.studio/</router-link></th>
				</tr>
				<tr>
					<th>Systems</th>
					<th>
						devices, software, operation system, network and communications equipment and ancillary services
						that are procured and owned or controlled by User
					</th>
				</tr>
				<tr>
					<th>Third Party Software</th>
					<th>software or data which has been licensed or provided to Licensor by third parties</th>
				</tr>
				<tr>
					<th>User Data</th>
					<th>
						Any data, information or material that was submitted to the Software by the User or any
						permitted third party
					</th>
				</tr>
			</tbody>
		</table>
		<div class="agreement">
			<ol class="list">
				<li>
					<h5>GRANT OF LICENSE</h5>
					<ol>
						<li>
							BY INDICATING ACCEPTANCE OF THIS EULA OR ACCESSING OR USING THE SOFTWARE, USER AGREES TO BE
							BOUND BY THE EULA.
						</li>
						<li>
							Licensor hereby grants, and User hereby accepts, a nontransferable, nonexclusive, worldwide,
							nonsublicensable, nonrefundable and nonexchangeable license to use the Software, subject to
							the conditions and for the period specified in this EULA.
						</li>
						<li>
							Licensor does not own any data, information or material that was submitted to the Software.
							User shall have sole responsibility for the accuracy, quality, integrity, legality,
							reliability, appropriateness, and intellectual property ownership or right to use of all
							User Data, and Licensor shall not be responsible or liable for the deletion, correction,
							destruction, damage, loss or failure to store any User Data.
						</li>
						<li>
							User has to register or authorize in accordance with section 4 of this EULA to enable the
							Software to execute on the User’s device.
						</li>
						<li>
							Any use of the Software not expressly permitted by this EULA is prohibited. Without limiting
							the generality of the foregoing, User shall not:
						</li>
						<ol>
							<li>permit persons other than User to access or use the Software (or any part thereof).</li>
							<li>remove or modify any program markings or any notice of Licensor.</li>
							<li>
								cause or permit reverse engineering (unless required by law for interoperability),
								disassembly, decompilation, modify, or attempt to derive the source code of the
								Software.
							</li>
							<li>
								use the Software (or any part thereof) in breach of any applicable laws or regulations.
							</li>
							<li>
								rent, lease, loan, sell, sublicense, distribute, transmit, network, or otherwise
								transfer the Software access to any third party.
							</li>
							<li>
								use hardware or software to multiplex or pool connections, or otherwise allow multiple
								users or multiple devices to access or use the Software indirectly through the licensed
								device.
							</li>
						</ol>
						<li>
							User shall obtain and operate all Systems needed to connect to, access or otherwise use the
							Software.
						</li>
					</ol>
				</li>
				<li>
					<h5>SOFTWARE SUPPORT</h5>
					<ol>
						<li>
							Licensor provides to User at no additional cost standard support services which include:
						</li>
						<ol>
							<li>Email communication support;</li>
							<li>
								Software updates, bug fixes and other revisions periodically made available by Licensor
								to Users.
							</li>
						</ol>
						<li>
							Licensor may provide software updates via download from a website designated by Licensor and
							User’s receipt thereof will require you to have an internet connection.
						</li>
						<li>
							Licensor has no obligation to provide software updates via any other media. Maintenance and
							support services do not include any new version or new release of the Software that Licensor
							may issue as a separate or new product, and Licensor may determine whether any issuance
							qualifies as a new version, new release, or software update in its sole discretion.
						</li>
						<li>
							Licensor has no obligation to provide maintenance and support services for any updates, bug
							fixes or releases of the Software that are not the most current versions or releases.
						</li>
					</ol>
				</li>
				<li>
					<h5>OWNERSHIP OF SOFTWARE</h5>
					<ol>
						<li>
							Licensor retains all rights to the Software. Licensor owns the Software and all copyright
							and other intellectual property rights therein, and this EULA does not transfer to User any
							title to or any proprietary or intellectual property rights in or to the Software, any
							updates or derivative works thereto, or any copyrights, patent rights, or trademarks
							embodied or used in connection therewith, except for the rights expressly granted in this
							EULA. The Software is protected by Slovak Republic laws and international treaty provisions.
							USER MAY NOT USE, COPY, OR MODIFY THE SOFTWARE, IN WHOLE OR IN PART, EXCEPT AS EXPRESSLY
							PROVIDED FOR IN THIS EULA.
						</li>
					</ol>
				</li>
				<li>
					<h5>REGISTRATION AND AUTHORISATION</h5>
					<ol>
						<li>
							To gain access to Software features, the User needs to register according to the terms and
							conditions specified herein.
						</li>
						<li>
							When registering, the User agrees to provide truthful, reliable, accurate and complete
							information and keep it up to date.
						</li>
						<li>
							The User confirms and guarantees that they have no legal and/or other obstacles to perform
							any actions on the Software. The User agrees that the Licensor may assume that all actions
							on the Software are performed by a person who has provided their data during registration.
						</li>
						<li>
							Licensor may block and/or delete the User's Account, deny access to all or part of the
							Software features without explanation, including in case when the User violates the terms
							and conditions of the EULA or provides incorrect information, or the Licensor has reason to
							believe that information is unreliable, incomplete, inaccurate and violates the terms and
							conditions of this EULA.
						</li>
						<li>
							The User's individual login and password required for authorization on the Software is
							intended for this User only. The User guarantees safety of their login and password,
							including that no third party will have access to them.
						</li>
						<li>
							Personal data about the User is processed following of the Privacy Policy published
							at:<router-link :to="{ name: 'LandingEn' }">https://vics.studio/</router-link>, and this
							EULA serves as the basis for personal data processing (personal data is processed so that
							one can use the Software's features).
						</li>
					</ol>
				</li>
				<li>
					<h5>LIABILITY</h5>
					<ol>
						<li>
							EXCEPT AS EXPRESSLY SPECIFIED HEREIN, THE SOFTWARE AND DOCUMENTATION ARE PROVIDED "AS IS"
							WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, LICENSOR
							DOES NOT WARRANT THAT THE SOFTWARE IS ACCURATE OR WILL MEET USER’S REQUIREMENTS, WILL
							OPERATE IN ANY COMBINATION THAT MAY BE SELECTED FOR USE BY USER OR IN COMBINATION WITH OTHER
							SOFTWARE, OR WILL OPERATE UNINTERRUPTED OR ERROR FREE. FURTHERMORE, LICENSOR DOES NOT
							WARRANT THAT ANY SOFTWARE ERRORS, DEFECTS OR INEFFICIENCIES WILL BE CORRECTED, NOR DOES
							LICENSOR ASSUME ANY LIABILITY FOR FAILURE TO CORRECT ANY SUCH ERROR, DEFECT OR INEFFICIENCY.
							LICENSOR MAKES NO WARRANTY, AND UER ASSUMES THE ENTIRE RISK, AS TO THE INTEGRITY OF ANY DATA
							AND THE RESULTS, CAPABILITIES, SUITABILITY, USE, NON-USE OR PERFORMANCE OF THE SOFTWARE. IN
							NO EVENT SHALL LICENSOR BE LIABLE TO USER FOR ANY DAMAGES RESULTING FROM OR RELATED TO THE
							USE OR PERFORMANCE OF THE SOFTWARE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ON
							BEHALF OF ITSELF AND ITS LICENSORS, LICENSOR HEREBY DISCLAIMS ALL OTHER WARRANTIES, EXPRESS
							OR IMPLIED, ORAL OR WRITTEN, INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES OF TITLE,
							NON‐INFRINGEMENT, INTEGRATION, ACCURACY, MERCHANTABILITY OR FITNESS FOR ANY PARTICULAR
							PURPOSE AND ALL WARRANTIES ARISING FROM ANY COURSE OF DEALING OR PERFORMANCE OR USAGE OF
							TRADE.
						</li>
					</ol>
				</li>
				<li>
					<h5>THIRD PARTY SOFTWARE</h5>
					<ol>
						<li>
							The Software may include software or data which has been licensed or provided to Licensor by
							third parties, and that the licensor of any Third Party Software embedded in or provided
							with the Software has a proprietary interest in such software or data. If a separate license
							agreement for an item of Third Party Software is delivered to User with the Software,
							included in the download package for the Software, or referenced in any Documentation that
							is included in the download package for the Software, then such Third Party Software and
							such separate license agreement shall govern User’s Use of that item or version of such
							Third Party Software. User’s right to use any Third Party Software provided with the
							Software shall be limited to the use necessary to operate the Software as permitted by this
							EULA or as provided in such separate third party license agreement.
						</li>
					</ol>
				</li>
				<li>
					<h5>TERM AND TERMINATION</h5>
					<ol>
						<li>
							This Agreement shall commence on the date User indicates his acceptance of this EULA and
							shall continue until terminated under the terms of this EULA.
						</li>
						<li>
							This EULA and other rights granted hereunder may be terminated immediately by Licensor at
							any time and without cause by providing User. Licensor may also terminate this EULA and
							other rights granted hereunder in the event User breaches any of the provisions of this
							EULA.
						</li>
						<li>
							Termination of the EULA will not void the obligations and claims of the Parties which, in
							accordance with the EULA and the applicable legislation, shall continue after the
							termination of the EULA. For the avoidance of any doubt, these include, but are not limited
							to, the grant of a license and other intellectual property rights, claims for damages and
							other obligations and claims which by their nature should not become void after the
							termination of the EULA.
						</li>
						<li>Licensor does not pay any refunds for termination of use of the Software.</li>
					</ol>
				</li>
				<li>
					<h5>GENERAL</h5>
					<ol>
						<li>
							Licensor may modify this EULA from time to time, and at any time, without notice to User,
							for any reason. Licensor may post or display notices of material changes on the Software.
							The form of such notice is at Licensor’s discretion. Once Licensor post or make them
							available on the Software, these changes become effective immediately and if You use the
							Platform after they become effective it will signify Your agreement to be bound by the
							changes. We recommend that You check back frequently and review this Agreement regularly so
							You are aware of the most current rights and obligations that apply to You.
						</li>
						<li>
							If any provision is adjudged by a court of competent jurisdiction to be unenforceable,
							invalid or otherwise contrary to law, such provision shall be interpreted so as to best
							accomplish its intended objectives and the remaining provisions shall remain in full force
							and effect.
						</li>
						<li>
							Any dispute or claim arising out of or in connection with this EULA, including any questions
							relating to its validity, interpretation, breach or termination, shall be governed by Slovak
							law. The Parties undertake to settle any disputes by mutual agreement. If this is not
							possible, the courts of the Slovak Republic will have jurisdiction to decide disputes.
						</li>
						<li>
							Neither party shall be liable for failing or delaying performance of its obligations
							resulting from any condition beyond its reasonable control; provided that such excusal from
							performance shall last only so long as such condition exists or so long as the excused party
							has had a reasonable opportunity to mitigate and/or eliminate the effect of such condition,
							whichever period is shorter.
						</li>
					</ol>
				</li>
			</ol>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		logoPath() {
			return require(`@/assets/images/vics-studio-logo.png`);
		},
	},
};
</script>

<style lang="scss" scoped>
.about-page {
	padding: 0 4rem;

	&__header {
		display: flex;
		align-items: flex-end;
		gap: 0.5rem;
		padding-left: 2rem;
		margin: 1rem 0;

		img {
			max-width: 3rem;
			height: auto;
		}

		h3 {
			font-weight: bold;
			margin-bottom: 0;
		}

		&__name {
			font-weight: bold;
		}
	}

	&__table {
		margin-bottom: 2rem;

		&__tbody th {
			border: 0.0625rem solid;
			padding: 0.25rem;
		}
	}
}
.agreement {
	ol {
		counter-reset: index;
		list-style-type: none;
	}
	.list > li::before {
		font-weight: bold;
	}

	ol > li::before {
		counter-increment: index;
		content: counters(index, '.', decimal) ' ';
	}

	h5 {
		display: inline;
		margin-top: 5rem;
	}
}
</style>
